<template>
  <div>
    <div style="padding: 20px">
      设备IMEI：<el-input style="width:500px" v-model="imei" placeholder="请输入IMEI"></el-input>
    </div>
    <div>
      <el-tabs @tab-click="tabClick" :value="msgId" :tab-position="tabPosition">
        <el-tab-pane name="0X8103" label="设置终端参数(0X8103)">
          <div>参数输入格式说明：参数ID,参数值,每行设置一项参数
            <br>
          示例: <br>
            0X0001,10
            <br>
            0X0029,60
            <br>
            设置终端心跳发送间隔10秒,缺省时间汇报间隔60秒
          </div>
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入参数"
              v-model="params['0X8103']">
          </el-input>

        </el-tab-pane>
        <el-tab-pane name="0X8104" label="查询终端参数(0X8104)">查询终端参数</el-tab-pane>
        <el-tab-pane name="0X8105" label="终端控制(0X8105)">
          <div>
            <el-radio-group v-model="params['0X8105']">
              <el-radio :label="'0X03'">关机</el-radio>
              <el-radio :label="'0X04'">重启</el-radio>
              <el-radio :label="'0X0C'">开始搜索</el-radio>
              <el-radio :label="'0X0D'">结束搜索</el-radio>
              <el-radio :label="'0X12'">WIFI查询</el-radio>
              <el-radio :label="'0X11-1'">电击等级1</el-radio>
              <el-radio :label="'0X11-2'">电击等级2</el-radio>
              <el-radio :label="'0X11-3'">电击等级3</el-radio>
              <el-radio :label="'0X11-4'">电击等级4</el-radio>
              <el-radio :label="'0X11-5'">电击等级5</el-radio>
              <el-radio :label="'0X11-6'">电击等级6</el-radio>
              <el-radio :label="'0X11-7'">电击等级7</el-radio>
              <el-radio :label="'0X11-8'">电击等级8</el-radio>
              <el-radio :label="'0X11-9'">电击等级9</el-radio>
            </el-radio-group>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8201" label="位置查询(0X8201)">位置查询</el-tab-pane>
        <el-tab-pane name="0X8202" label="临时位置跟踪控制(0X8202)">
          <div>
            参数输入格式说明：位置上报时间间隔,位置跟踪有效期
            <br>
            <el-input v-model="params['0X8202']" placeholder="请输入参数"></el-input>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8400" label="电话回拨(0X8400)">
          <div>
            <el-input v-model="params['0X8400']" placeholder="请输入电话号码"></el-input>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8401" label="设置电话本(0X8401)">
          <div>参数输入格式说明：电话号码,联系人名称,每行设置一个号码
            <br>
            示例: <br>
            13713511087,张三
            <br>
            13713511088,李四
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入号码"
                v-model="params['0X8401']">
            </el-input>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8F09" label="同步时间(0X8F09)">同步时间</el-tab-pane>
        <el-tab-pane name="0X8F10" label="设置周期定位(0X8F10)">
          <div>参数输入格式说明：星期几,时间(支持多项)
            <br>
            示例: <br>
            0,08:00,14:12
            <br>
            2,09:00
            <br>
            设置星期日08:00及14:12和星期二09:00
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入参数"
                v-model="params['0X8F10']">
            </el-input>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8F11" label="语音包下发(0X8F11)">
          <div>
            参数输入格式说明：播放次数,循环播放间隔,语音文件类型,下载地址
            <br>
            <el-input v-model="params['0X8F11']" placeholder="请输入参数"></el-input>
          </div>
        </el-tab-pane>
        <el-tab-pane name="0X8F12" label="深度休眠模式(0X8F12)">
          <el-switch
              v-model="params['0X8F12']"
              >
          </el-switch>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div style="width:100%;text-align: center">
      <el-button @click="onSubmit" type="primary">发送指令</el-button>
    </div>
  </div>

</template>

<script>
import {sendJt808} from "../../api/jt808";

export default {
  name: "index",
  data() {
    return {
      tabPosition: 'left',
      imei:"865917000796205",
      msgId:"0X8103",
      params:{
        "0X8103":"0X0001,10\n0X0029,60",
        "0X8202":"10,600",
        "0X8401":"13713511087,张三\n13713511088,李四",
        "0X8F10":"0,08:00,14:12\n2,09:00",
        "0X8F11":"3,5,1,http://cdn.youshusoft.com/file/mario.amr",
      }
    };
  },
  methods:{
    tabClick(tab){
      this.msgId = tab.name
      window.console.log(tab.name);
    },
    onSubmit(){
      let notParamMsgIdArray = ["0X8104","0X8201","0X8F09"]
      let imei = this.imei;
      if(!imei){
        this.$message.error("请输入IMEI!")
        return;
      }
      let msgId = this.msgId;
      let param = null;
      if(notParamMsgIdArray.indexOf(msgId) == -1){
        param = this.params[msgId];
        window.console.log(param)
        if(msgId == "0X8F12"){
          param = param || false
        }else{
          if(param == null || param==""){
            this.$message.error("请输入参数!")
            return;
          }
        }

      }

      window.console.log({imei,msgId,param})
      sendJt808(imei,msgId,param).then(() => {
        this.$message({
          message: '指令发送成功',
          type: 'success'
        });
      }).catch((msg) => {
        this.$message.error(msg);
      });
    }
  }
}
</script>

<style scoped>

</style>
